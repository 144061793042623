import { range } from '@/utils/helpers/methods';
import dayjs from 'dayjs';


export const dateFormatYMD = 'YYYY-MM-DD';
export const dateFormatMDY = 'MM/DD/YYYY';
export const dateFormatShortMDY = 'M/D/YY';
export const dateFormatLongAbbrMDY = 'MMM D, YYYY'
export const dateFormatLongMDY = 'MMMM D, YYYY';
export const dateTimeFormatLongMDYT = 'MMMM D, YYYY h:mm A';
export const dateTimeFormatDT = 'dddd h:mm A';
export const dateFormatUTC = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

/**
 * Since each of the formats are already being used in many places, instead of refactoring the entire app
 * Use this func. to get the desired format; with more time we can make into a Dictionary and
 * remove the different calls to the formats, one at a time...
 * @param format a...h values for the various formats available
 * @returns {string} the format value
 */
function getDateFormat(format) {
  switch (format) {
    case 'a':
    default:
      return dateFormatYMD;
    case 'b':
      return dateFormatMDY;
    case 'c':
      return dateFormatShortMDY;
    case 'd':
      return dateFormatLongAbbrMDY;
    case 'e':
      return dateFormatLongMDY;
    case 'f':
      return dateTimeFormatLongMDYT;
    case 'g':
      return dateTimeFormatDT;
    case 'h':
      return dateFormatUTC;
  }
}

/**
 * Create a humanized and formatted DOB property
 * showing a formatted string with the age of the person
 * @param dateOfBirth typeof Date | string : DOB
 * @param format optional formatting
 * @param isFullWidth change the formatting based on this flag;
 * #5D6B98 is $cool-gray-500
 * @returns {`${string} <span class="has-text-grey-light"> ${string} </span>`}
 */
export const getFormattedBirthday = (dateOfBirth, format, isFullWidth = true) => {
  dateOfBirth = dateOfBirth ?? new Date(dateOfBirth);
  const humanizedDate = getHumanizedDate(dateOfBirth, format);
  const age = getPersonsAgeFromDate(dateOfBirth);
  const formattedAge = isFullWidth ? `- (${age} years old)` : `- (${age} Yo)`;
  return`${humanizedDate} <span class="has-text-grey-light"> ${formattedAge} </span>`;
}

/**
 * Helper for humanizing a date string
 * @param date some date string
 * @param format the format desired for the date
 * @returns {string} date formatted
 */
export const getHumanizedDate = (date, format) => {
  if ( date === null || typeof date === undefined) return 'N/A';
  // dayjs lib w/ desired formatting
  return dayjs(date).format(getDateFormat(format));
}

/**
 * Calculates a person's age from their DOB.
 * @param date the person's date of birth
 * @returns {number} the age of the person
 */
export const getPersonsAgeFromDate = (date) => {
  if ( date === null || typeof date === undefined) return 0;
  let today = new Date().getFullYear();
  let born = new Date(date).getFullYear();
  return today - born;
}

export const dateMonthDaysList = range(1, 32);

export const dateWeekdaysOneCharList = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const dateWeekdays = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const dateFormatOptions = [
    { key: 'dmy', label: 'Day / Month / Year' },
    { key: 'mdy', label: 'Month / Day / Year' },
    { key: 'ymd', label: 'Year / Month / Day' },
];

export const datePresetIntervalsList = [
    { value: ['weekly'], label: 'Every' },
    { value: [2], label: 'Every Other' },
    { value: [1], label: 'First' },
    { value: [-1], label: 'Last' },
    { value: [1, 3], label: 'First & Third' },
    { value: [2, 4], label: 'Second & Fourth' },
    { value: ['monthly'], label: 'Fixed Dates' },
];

export const datePresetRangesList = [
    { key: 'year', label: 'Year' },
    { key: 'quarter', label: 'Quarter' },
    { key: 'month', label: 'Month' },
    { key: 'dates', label: 'Dates' },
    { key: 'date', label: 'Date' },
];

export const dateImportantList = [
    { key: 'anniversary', label: 'Anniversary' },
    { key: 'birthday', label: 'Birthday' },
    { key: 'other', label: 'Other' },
];




