import Vue from 'vue';
import { cloneDeep, compact } from '@/utils/helpers/methods';
import { HTTP } from '@/utils/http';
import {
  advancedAggs,
  productionReportingFields,
  productionAggs,
  sortMethod,
  typesAndSubTypes,
  getAdvancedSupplierQuery,
  getLocationQuery,
} from '@/utils/helpers/es/suppliers';
import { cleanProductionData } from '@/utils/helpers/es/production_reporting';

const defaultAggs = {
  total_count: {
    value: 0
  },
  suppliersAggregations: {
    advanced: {
      manual: {
        count: {
          value: 0
        }
      },
      suggestions: {
        count: {
          value: 0
        }
      },
      verified: {
        count: {
          value: 0
        }
      }
    },
    production_reports: {
      current_period: {
        by_quarter: {
          buckets: [],
        }
      },
      previous_period: {
        by_quarter: {
          buckets: [],
        }
      },
      total_booking_count: { value: 0 },
      total_commission: { value: 0 },
      total_revenue: { value: 0 }
    }
  }
};

const defaultQueryFields = [
  { key: 'email', label: 'Email' },
  { key: 'name^3', label: 'Name' },
  { key: 'name.no_punctuation', label: 'Name without punctuation' },
  { key: 'phone_number', label: 'Phone Number' },
  { key: '_embedded.address.locality', label: 'Address' },
  { key: '_embedded.address.administrative_area_level_1', label: 'City' },
  { key: '_embedded.location.address.components.country_iso_2', label: 'Country' },
  { key: '_embedded.location.address.components.region', label: 'Region' },
  { key: '_embedded.group.id', label: 'Group ID' }, // Todo MasterData: different key for properties/property_collections
  { key: '_embedded.group.codes', label: 'Chain Code' }, // Todo MasterData: different key for properties/property_collections

];

const defaultFilters = {
  companies: [],
  dates: {
    from: null,
    month: 1,
    range: 'year',
    to: null,
    quarter: 1,
    year: 'all',
  },
  fields: {
    query: defaultQueryFields.map(({ key }) => key),
  },
  hosts: [],
  iatas: [],
  locations: [],
  queryStrings: [],
  sort: { 'total_revenue': 'desc' },
  source_identities: {
    source_ids: [],
    sources: [],
  },
  tags: [],
  suppliers: [],
  with_bookings: false,
  supplier_advanced: ['verified', 'manual', 'no_suggestions', 'with_suggestions'],
  suggestions_type: ['open', 'open_low', 'open_medium', 'open_high', 'dismissed', 'confirmed'],
  supplier_types: [],
  supplier_groups: [],
  chain_codes: [],
  group_ids: [],
  programs: [],
}

const defaultBillingProfile = {
  id: null,
  source: 'manual',
  type: 'supplier',
  is_default: false,
  attn: null,
  name: null,
  email: null,
  formatted_address: null,
  formatted_phone_number: null,
  currency: {
      id: null,
      country_codes: [],
      label: null,
      sign: null,
      symbol: null,
      _embedded: {}
  },
  mode: 'passthrough', // passthrough | contact | custom
  notes: null,
  contact_id: null
};

const defaultSupplier = {
  location: {
    address: {
      components: {},
      formatted: ''
    },
  },
  note: {
    body: null
  },
  notes: [],
  type: 'property',
  sub_type: null,
  phone_numbers: null,
  website: {
    display: null,
    url: null
  },
  source: '', // sion for verified, anything else for manual
  suggestion_stats: { // optional, only if manual suppliers
    confidence: 'high', // high, medium, low
    count: 0,
    status:'' // open, confirmed, dismissed
  },
  _embedded: {
    contacts: [],
    default_commission_rate: {
      amount: 10,
      type: 'percentage'
    },
    default_billing_profile: cloneDeep(defaultBillingProfile),
    integration_identities: [],
    tags: []
  },
};

const defaultReportingDates = {
  field: 'check_in',
  startDate: null,
  endDate: null,
}

const defaultPreviousReportingDates = {
  field: 'check_in',
  startDate: null,
  endDate: null,
}

const supplierTypes = [
  {
    key: 'airline',
    label: 'Airline',
    icon: 'plane',
    sub_types: [
      {
        key: 'commercial',
        label: 'Commercial'
      },
      {
        key: 'private',
        label: 'Private'
      }
    ]
  },
  {
    key: 'car',
    label: 'Car',
    icon: 'car-01',
    sub_types: []
  },
  {
    key: 'cruise_line',
    label: 'Cruise Line',
    icon: 'anchor',
    sub_types: []
  },
  {
    key: 'dmc',
    label: 'DMC',
    icon: 'globe-06',
    sub_types: []
  },
  {
    key: 'insurance',
    label: 'Insurance',
    icon: 'users-check',
    sub_types: []
  },
  {
    key: 'property',
    label: 'Property',
    icon: 'building-04',
    sub_types: []
  },
  {
    key: 'rail',
    label: 'Rail',
    icon: 'train',
    sub_types: []
  },
  {
    key: 'tour_operator',
    label: 'Tour Operator',
    icon: 'map-02',
    sub_types: []
  },
  {
    key: 'other',
    label: 'Other',
    icon: 'passport',
    sub_types: []
  }
]

const state = {
  aggs: cloneDeep(defaultAggs),
  bulk_suppliers_processing: {
    status: false,
    count: 0,
    actionId: null
  },
  dateFields: [
    { key: 'last_active', label: 'Last Active' },
    { key: 'created_at', label: 'Created' },
  ],
  editing: cloneDeep(defaultSupplier),
  filters: cloneDeep(defaultFilters),
  loading: {
    sources: false,
    types: false,
    search: false
  },
  pagination: {
    page: 1,
    per: 25,
    total: 0,
  },
  reporting_dates: cloneDeep(defaultReportingDates),
  previous_period_dates: cloneDeep(defaultPreviousReportingDates),
  selected: [],
  supplier: cloneDeep(defaultSupplier),
  supplier_types: cloneDeep(supplierTypes),
  programs: [],
  suppliers: [],
  refresh: false,
  sync: false,
  query: {},
  queryFields: cloneDeep(defaultQueryFields),
};

const mutations = {
  ADD_INTEGRATION_IDENTITY_TO_SUPPLIER(state, payload) {
    state.editing._embedded.integration_identities.splice(payload.index, 0, payload.val);
  },
  ADD_TO_SUPPLIER(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        // Vue.set(obj, node, payload.val);
        obj[node].push(payload.val);
      } else {
        obj = obj[node];
      }
    });
    // state.supplier._embedded.contacts.push(payload.val);
  },
  RESET_SUPPLIERS(state, payload) {
    switch (payload) {
      case 'editing':
        state.editing = cloneDeep(defaultSupplier);
        break;
      case 'supplier':
        state.supplier = cloneDeep(defaultSupplier);
        break;
      case 'filters':
        state.filters = cloneDeep(defaultFilters);
        break;
      default:
    }
  },
  REMOVE_CONTACT_FROM_SUPPLIER(state, payload) {
    // used?
    if (
      state.supplier._embedded &&
      state.supplier._embedded.contacts &&
      state.supplier._embedded.contacts !== undefined
    ) {
      state.supplier._embedded.contacts.splice(payload.index, 1);
    }
  },
  SET_INTEGRATION_IDENTITY_FIELD_FOR_SUPPLIER(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state.editing._embedded.integration_identities[payload.index];
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_SUPPLIERS_ATOMIC(state, payload) {
    const nodes = payload.key.split('.');
    let obj = state;
    let i = 0;
    nodes.forEach((node) => {
      i += 1;
      if (i === nodes.length) {
        Vue.set(obj, node, payload.val);
      } else {
        obj = obj[node];
      }
    });
  },
  SET_BULK_SUPPLIERS_PROCESSING(state, payload) {
    state.bulk_suppliers_processing = payload;
  },
  TOGGLE_SELECTED_SUPPLIERS(state, supplier) {
    const index = state.selected.findIndex((selectedItem) => selectedItem.id === supplier.id);
    if (index >= 0) {
      state.selected.splice(index, 1);
    } else {
      state.selected.push(supplier);
    }
  },
  TOGGLE_EDITING_SUPPLIER_DESTINATION(state, payload) {
    const supplier = state.editing;
    const { val } = payload;
    const { address } = supplier._embedded;
    if (address) {
      const { destinations } = address;
      const index = destinations.findIndex(
        (destination) => destination.source_id === val.source_id
      );
      if (index >= 0) {
        address.destinations.splice(index, 1);
      } else {
        address.destinations.push(val);
      }
    }
  },
  TOGGLE_EDITING_SUPPLIER_TAG(state, payload) {
    const index = state.editing._embedded.tags.findIndex(
      (tagItem) => tagItem.id === payload.val.id
    );
    if (index < 0) {
      state.editing._embedded.tags.push(payload.val);
    } else {
      state.editing._embedded.tags.splice(index, 1);
    }
  },
  TOGGLE_SUPPLIERS_FILTER(state, payload) {
    const filter = state.filters[payload.key];
    const { val } = payload;
    const index =
      typeof val === 'string'
        ? filter.indexOf(val)
        : filter.findIndex((filterItem) => filterItem.id === val.id);
    if (index < 0) {
      filter.push(val);
    } else {
      filter.splice(index, 1);
    }
  },
  SET_SUPPLIERS_FILTERS(state, payload) {
    state.filters = payload;
  }
};

const getters = {
  bootstrappingSuppliers: (state) => {
    return state.loading.sources || state.loading.types;// todo: rethink this when we drop sources
  },
  bulkSuppliersProcessingInfo: (state) => state.bulk_suppliers_processing,
  suppliersSearching: (state) => state.loading.search,
  supplier: (state) => state.supplier,
  supplierEditing: (state) => state.editing,
  supplierBillingProfile: (state, getters) => getters.supplier?._embedded?.default_billing_profile || {},
  supplierLocation: (state, getters) => getters.supplier?.location,
  suppliersAggregations: (state) => state.aggs,
  suppliersTotal: (state) => state.aggs.total_count?.value || 0,
  supplierSync: (state) => state.sync,
  supplierTypes: (state) => state.supplier_types || [],
  supplierPrograms: (state) => state.programs,
  suppliers: (state) => state.suppliers,
  suppliersProductionData: (state, getters, rootState, rootGetters) => cleanProductionData(getters.suppliersAggregations, rootGetters['productionReporting/selectedReportingOption']),
  suppliersSelected: (state) => state.selected,
  suppliersDateFields: (state) => state.dateFields,
  suppliersFilters: (state) => state.filters,
  suppliersPagination: (state) => state.pagination,
  suppliersRefresh: (state) => state.refresh,
  suppliersEsQuery: (state, getter, _rootState, rootGetters) => {
    const companies = getter.suppliersFilters.companies || [];
    const hosts = getter.suppliersFilters.hosts || [];
    const combinedCompanies = [...companies, ...hosts];

    const iatas = getter.suppliersFilters.iatas || [];
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];
    const from = state.pagination.per * (state.pagination.page - 1);
    const sort = sortMethod(getter.suppliersFilters.sort, companies, getter.suppliersFilters.iatas, currentReportingDates);
    // const fullIatasUnmanaged = rootGetters['companies/iatasUnfiltered'].filter(iata =>
    //   iatas.includes(iata.id) && iata.is_managed === false
    // );
    // const iataCompanies = fullIatasUnmanaged.map(iata => iata.company_id);

    const { id: companyId } = rootGetters['companies/company'];
    const companyContractors = rootGetters['companies/companyContractors'];

    const query = {
      bool: {
        must: [
          {
            bool: {
              should: [
                { term: { source: 'sion' } },
                { ...rootGetters['quickSearchFilter/getCompaniesFilter'](combinedCompanies) },
              ]
            }
          },
        ],
      },
    }

    // Statuses / advanced filters
    const advanced = getter.suppliersFilters.supplier_advanced;
    const suggestionTypes = getter.suppliersFilters.suggestions_type;
    const withBookings = getter.suppliersFilters.with_bookings;

    if (advanced?.length > 0 || withBookings) {
      const advancedQuery = getAdvancedSupplierQuery(
        advanced,
        combinedCompanies,
        getter.suppliersFilters.iatas,
        currentReportingDates,
        suggestionTypes,
        withBookings
      );
      query.bool.must.push(...advancedQuery);
    }

    // Locations
    const { locations } = getter.suppliersFilters;
    if (locations?.length > 0) {
      const locationQuery = getLocationQuery(locations);
      query.bool.must.push({ bool: { should: locationQuery } });
    }

    // Tags
    const { tags } = getter.suppliersFilters;
    if (tags?.length > 0) {
      const tagsNestedPath = '_embedded.tags';
      const companyContractorIds = companyContractors.map(contractor => contractor.id);
      const tagCompanyIds = [companyId, ...companyContractorIds];
      const tagsShouldQuery = tags.map((tag) => {
        return {
          bool: {
            must: [
              { terms: { [`${tagsNestedPath}.company_id`]: tagCompanyIds } },
              { match: { [`${tagsNestedPath}.label`]: tag } }
            ]
          }
        }
      });
      const tagsQuery = {
        nested: {
          path: tagsNestedPath,
          query: {
            bool: {
              should: tagsShouldQuery
            }
          }
        }
      }
      query.bool.must.push(tagsQuery);
    }

    // Preferred programs
    if (getter.suppliersFilters.programs.length > 0) {
      const programsQuery = {
        nested: {
          path: '_embedded.preferred_programs',
          query: {
            bool: {
              must: [
                { terms: { '_embedded.preferred_programs.id':  getter.suppliersFilters.programs } }
              ]
            }
          }
        }
      }
      query.bool.must.push(programsQuery);
    }
    // OLD? Can remove, keeping for reference
    // if (locationCodes.length > 0) {
    //   const locationsQuery = {
    //     nested: {
    //       path: 'location.nearby_places',
    //       query: {
    //         bool: {
    //           must: [
    //             { terms: { 'location.nearby_places.place.data.code': locationCodes } }
    //           ]
    //         }
    //       }
    //     }
    //   };
    //   query.bool.must.push(locationsQuery);
    //   const locationTypeQueries = getLocationTypeQueries(getter.suppliersFilters.locations);
    //   locationTypeQueries.forEach(locationTypeQuery => query.bool.must.push(locationTypeQuery));
    // }

    if (getter.suppliersFilters.supplier_groups.length > 0) {
      const ids = getter.suppliersFilters.supplier_groups.map(({ id }) => id );
      const groupsQuery = {
        bool: {
          must: [
            { terms: { '_embedded.group.id':  ids } }
          ]
        }
      }
      query.bool.must.push(groupsQuery);
    }

    // Types & SubTypes
    if (getter.suppliersFilters.supplier_types.length > 0) {
      const typesQuery = typesAndSubTypes(getter.suppliersFilters.supplier_types);
      query.bool.must.push(typesQuery);
    }

    if (getter.suppliersFilters.suppliers.length > 0) {
      const ids = compact(getter.suppliersFilters.suppliers.map(({ id }) => id));
      const supplierQuery = {
        bool: {
          should: [
            { terms: { 'id': ids } },
          ],
        },
      };
      query.bool.must.push(supplierQuery);
    }
    if (getter.suppliersFilters.chain_codes?.length > 0) {
      query.bool.must.push({
        terms: { '_embedded.group.codes': getter.suppliersFilters.chain_codes },
      });
    }

    if (getter.suppliersFilters.queryStrings?.length > 0) {
      const queryStringsQuery = {
        bool: {
          should: [],
        },
      };
      getter.suppliersFilters.queryStrings.forEach((queryString) => {
        if (queryString) {
          queryStringsQuery.bool.should.push({
            multi_match: {
              query: queryString.toUpperCase(),
              fields: getter.suppliersFilters.fields.query,
              type: 'bool_prefix',
              operator: 'and',
              fuzziness: 1
            },
          });
        }
      });
      query.bool.must.push(queryStringsQuery);
    }

    const supplierProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        combinedCompanies,
        getter.suppliersFilters.iatas,
        currentReportingDates
      )
    }

    const aggs = {
      total_count: {
        cardinality: {
          field: '_id'
        }
      },
      grouped_by_name_and_address: {
        terms: {
          field: 'group_by_key',
          size: 101,
        },
        aggs: {
          docs: {
            top_hits: {
              _source: ['id', 'name', 'location.address.formatted'],
              size: 1
            }
          }
        }
      },
      ...advancedAggs(combinedCompanies, iatas, currentReportingDates, hosts),
      production_reports: {
        nested: {
          path: '_embedded.production_reports'
        },
        aggs: {
          current_period: productionAggs(combinedCompanies, iatas, currentReportingDates),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(combinedCompanies, iatas, previousReportingDates);
    }

    return  {
      _source: { excludes: '_embedded.production_reports' },
      aggs,
      size: state.pagination.per,
      from: from >= 0 ? from : 0,
      sort,
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: supplierProductionReportingFields('commission'),
        total_revenue: supplierProductionReportingFields('revenue'),
        total_booking_count: supplierProductionReportingFields('booking_count')
      }
    };
  },
  supplierEsQuery: (state, getter, rootState, rootGetters) => (supplierId) =>  {
    // Todo: abstract shared functionality with suppliersEsQuery?
    // TODO: add hosts
    const companies = getter.suppliersFilters.companies || [];
    const iatas = getter.suppliersFilters.iatas || [];
    // const { id: companyId } = rootGetters['companies/company'];
    // const companies = [companyId];
    const currentReportingDates = rootGetters['productionReporting/currentReportingDates'];
    const previousReportingDates = rootGetters['productionReporting/previousReportingDates'];

    const query = {
      bool: {
        must: [
          {
            bool: {
              should: [
                { term: { source: 'sion' } },
                { ...rootGetters['quickSearchFilter/getCompaniesFilter'](companies) },
              ]
            }
          },
          {
            bool: {
              must: [{ term: { 'id': supplierId } }]
            }
          }
        ]
      },
    }
    const supplierProductionReportingFields = (fieldName = '') => {
      return productionReportingFields(
        fieldName,
        companies,
        iatas,
        currentReportingDates
      )
    }

    const aggs = {
      'production_reports': {
        'nested': {
          'path': '_embedded.production_reports'
        },
        'aggs': {
          'current_period': productionAggs(
            companies,
            iatas,
            currentReportingDates
          ),
        }
      }
    };

    if (previousReportingDates.startDate && previousReportingDates.endDate) {
      aggs.production_reports.aggs['previous_period'] = productionAggs(
        companies,
        iatas,
        previousReportingDates
      );
    }

    return  {
      _source: { excludes: '_embedded.production_reports' },
      aggs,
      query: {
        constant_score: {
          filter: query,
        },
      },
      script_fields: {
        total_commission: supplierProductionReportingFields('commission'),
        total_revenue: supplierProductionReportingFields('revenue'),
        total_booking_count: supplierProductionReportingFields('booking_count')
      }
    };
  },
  suppliersQueryFields: (state) => state.queryFields,
  hasOpenSupplierSuggestions: (state) => state.supplier.suggestion_stats?.status === 'open'
};

const actions = {
  async doCreateSupplier({ commit }, payload) {
    const { mutate, ...params } = payload;
    const { data } = await HTTP.post('/suppliers', params);
    const [supplier] = data.data.suppliers;
    if (mutate) commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: supplier });
    return data;
  },
  async doDeleteSupplier({ commit }, payload) {
    const { id, ...params } = payload;
    const { data } = await HTTP.delete(`/suppliers/${id}`, { params });
    commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: [] });
    return data;
  },
  async doDuplicateSupplier({ commit }, payload) {
    // used?
    const { id, ...params } = payload;
    const { data } = await HTTP.post(`/suppliers/${id}`, params);
    const [supplier] = data.data.suppliers;
    commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: supplier });
    return data;
  },
  async doGetSupplier({ commit }, { id, zoom }) {
    const defaultZoom = 'address,billing_profiles,booking_category,company,default_billing_profile,default_commission_rate,contacts,integration_identities,invite,tags,currency,phone_numbers,currency,identities,group,suggestions,preferred_programs,notes,note,permissions,suggestion_stats';
    const payload = { id, zoom: zoom || defaultZoom };
    const { data } = await this._vm.$api.suppliers.get(payload);
    const [supplier] = data.suppliers;
    if (!supplier._embedded.default_billing_profile) {
      supplier._embedded.default_billing_profile = defaultBillingProfile;
    }
    if (!payload.ignore) {
      commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: supplier });
    }
    return data;
  },
  async doGetSupplierPrograms({ commit }) {
    const query = {
      'size': 50,
      'sort': [
        {
          'key': {
            'order': 'asc'
          }
        }
      ],
      'query': {
        'bool': {
          'must': [
            { 'match': { 'visibility': 'public' } }
          ]
        }
      }
    };
    const { data } = await this._vm.$api.suppliers.getPreferredPrograms(query);
    const programs = data?.hits?.hits.map(({ _source }) => _source ) || [];
    commit('SET_SUPPLIERS_ATOMIC', { key: 'programs', val: programs });
  },
  async doGetSuppliers({ commit }, payload) {
    const { ignore, ...params } = payload;
    const { data } = await HTTP.get('/suppliers', { params });
    if (!ignore) commit('SET_SUPPLIERS_ATOMIC', { key: 'suppliers', val: data.data.suppliers });
    return data;
  },
  async doSearchSuppliers({ commit }, payload) {
    commit('SET_SUPPLIERS_ATOMIC', { key: 'loading.search', val: true });
    const { ignore, ...params } = payload;
    const { data } = await this._vm.$api.suppliers.search(params);

    const suppliers = data.hits.hits.map((hit) => {
      const scriptFields = {};
      Object.keys(hit.fields).forEach((key) => {
        const val = hit.fields[key];
        [scriptFields[key]] = val;
        return val;
      })
      return Object.assign(hit._source, scriptFields);
    });

    if (!ignore) {
      commit('SET_SUPPLIERS_ATOMIC', { key: 'suppliers', val: suppliers });
      commit('SET_SUPPLIERS_ATOMIC', { key: 'aggs', val: data.aggregations });
      commit('SET_SUPPLIERS_ATOMIC', { key: 'pagination.total', val: data.hits.total.value });
    }
    commit('SET_SUPPLIERS_ATOMIC', { key: 'loading.search', val: false });
    return {
      suppliers,
      aggs: data.aggregations,
    };
  },
  async doUpdateSupplier({ commit }, payload) {
    const { id, mutate, ...params } = payload;
    const { data } = await HTTP.put(`/suppliers/${id}`, params);
    const [supplier] = data.data.suppliers;
    if (mutate) commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: supplier });
    return data;
  },
  async doUpdateSupplierAtomic({ commit }, payload) {
    const { id, mutate, ...params } = payload;
    const { data } = await HTTP.patch(`/suppliers/${id}`, params);
    const [supplier] = data.data.suppliers;
    if (mutate) commit('SET_SUPPLIERS_ATOMIC', { key: 'supplier', val: supplier });
    return data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
