import { SEARCH } from '@/utils/http';
import {stripIndexVersioning} from '@/utils/helpers/es';

const indexesFieldMapping = {
  batches: {
    fuzzy: [
      'padded_number.case_insensitive^5',
      'padded_number.autocomplete',
      '_embedded.import.file_name.autocomplete^1'
    ],
    exact: [
      'number.case_insensitive^5',
      'number.autocomplete',
    ]
  },
  clients: {
    fuzzy: [
      'name.first^5',
      'name.full^3',
      'name.full.autocomplete',
      'first_name^5',
      'full_name^3',
      'full_name.autocomplete',
      'email.case_insensitive^5',
      'email.autocomplete'
    ],
    exact: []
  },
  imports: {
    fuzzy: [
      'file_name.autocomplete^1'
    ],
    exact: []
  },
  pnrs: {
    fuzzy: [
      'record_locator.case_insensitive^5',
      'record_locator.autocomplete'
    ],
    exact: []
  },
  preferred_programs: {
    fuzzy: [
      'name.autocomplete'
    ]
  },
  tags: {
    fuzzy: [],
    exact: [
      'label.autocomplete^5'
    ],
  },
  trips: {
    fuzzy: [
      'name.autocomplete',
      'number.case_insensitive^3',
      'number.autocomplete'
    ],
    exact: []
  },
  suppliers: {
    fuzzy: [
      'name.autocomplete^3',
      'name.autocomplete_no_punctuation',
      'location.address.components.country_iso2.autocomplete',
      'location.address.components.city.autocomplete',
      'location.address.components.region.autocomplete',
      'location.address.formatted.autocomplete',
    ],
    exact: [
      'number.case_insensitive',
      'group.codes.case_insensitive',
      'group.ids.case_insensitive'
    ],
  },
  supplier_groups: {
    fuzzy: ['name.autocomplete^3', 'name.autocomplete_no_punctuation'],
    exact: ['codes.case_insensitive^3', 'id.case_insensitive'],
  },
  airports: {
    fuzzy: ['name.autocomplete^3'],
    exact: ['code.case_insensitive'],
  },
  cities: {
    fuzzy: ['name.autocomplete^3'],
    exact: [
      'code.case_insensitive',
      'country_iso2.case_insensitive'
    ]
  },
  countries: {
    fuzzy: ['name.autocomplete^3'],
    exact: [
      'iso2.case_insensitive',
      'iso3.case_insensitive'
    ]
  },
  geographic_regions: {
    fuzzy: [
      'name.autocomplete^3',
      'parent_region.name.autocomplete',
      'country.name.autocomplete'
    ],
    exact: [
      'code.case_insensitive^5',
      'parent_region.code.case_insensitive',
      'country.iso2.case_insensitive',
      'country.iso3.case_insensitive'
    ]
  },
  neighborhoods: {
    fuzzy: [
      'name.autocomplete^3',
      'area.autocomplete^3',
      'city.name.autocomplete'
    ],
    exact: ['city.code.case_insensitive']
  }
};

const suggestSupportedIndexes = [
  'sion_bookings',
  'sion_payments',
  'sion_unmatched_payments',
  'sion_statements',
];

const dedupeResultsWithTags = (results) => {
  const resultsLabels = [...results].map(({ _source }) => _source.label);
  return results.filter((result, index) => {
    if (result._index.includes('sion_tags')) {
      const dupeLabelIndex = resultsLabels.indexOf(result._source.label);
      return dupeLabelIndex === index;
    } else {
      return true;
    }
  })
}

const getters = {
  getCompaniesFilter: () => (companies, fieldName= 'company_id') => {
    return {
      bool: {
        should: [
          {
            bool: {
              must_not: [{ exists: { field: '_embedded.company.id' } }],
              must: [{ terms: { [fieldName]: companies }}]
            },
          },
          {
            bool: {
              must: [{ terms: { '_embedded.company.id': companies }}]
            }
          }
        ]
      }
    }
  },
  getAutocompleteFields: () => (val, indexes) => {
    const generateFields = (type) => {
      return indexes.reduce((fields, i) => {
        const indexFields = indexesFieldMapping[i];
        if (!indexFields || !indexFields[type]) return fields;
        else return [...fields, ...indexFields[type]];
      }, []);
    }
    const fuzzyFields = [...new Set(generateFields('fuzzy'))];
    const exactFields = [...new Set(generateFields('exact'))];
    return [
      {
        'multi_match': {
          'query': val,
          'fields': fuzzyFields,
          'analyzer': 'synonym_analyzer',
          'operator': 'and'
        }
      },
      {
        'multi_match': {
          'query': val,
          'fields': exactFields,
          'analyzer': 'standard',
          'operator': 'and'
        }
      }
    ]
  },
  getIndexSpecificFilters: (state, getters, rootState, rootGetters) => (
    searchIndexes,
    indexFilterConfig = {
      suppliers: { source: 'any', type: 'any', exclude: [], company_ids: [], agent_ids: [] },
      clients: { company_ids: [], agent_ids: [] },
      tags: { company_ids: [] },
    }
  ) => {
    const { id: companyId } = rootGetters['companies/company'];
    const companyContractors = rootGetters['companies/companyContractors'];
    const companyContractorIds = companyContractors.map(contractor => contractor.id);
    const hostAgencies = rootGetters['companies/iatas'].map(iata => iata.company_id);
    const companies = [companyId];
    const allCompanies = [...companyContractorIds, ...hostAgencies, companyId];
    const { access, id: employeeId } = rootGetters['employees/currentEmployee'];

    /* -------------
      Tags Filter
    --------------- */
    let tagsCompanies = [];
    if (indexFilterConfig.tags?.company_ids?.length > 0) {
      tagsCompanies = indexFilterConfig.tags.company_ids;
    } else {
      tagsCompanies = [...companies, ...companyContractorIds];
    }
    const tagsFilter = {
      must: [getters.getCompaniesFilter(tagsCompanies)]
    };

    /* -------------
      Trips Filter
    --------------- */
    let tripsCompanies = [...companies];
    if (access.ic.bookings.read) {
      tripsCompanies = [...companies, ...companyContractorIds];
    }
    const tripsFilter = {
      must: [getters.getCompaniesFilter(tripsCompanies)]
    };

    /* -------------
      PNR Filter
    --------------- */
    let pnrCompanies = [...companies];
    if (access.ic.bookings.read) {
      pnrCompanies = [...companies, ...companyContractorIds];
    }
    const pnrFilter = {
      must: [
        {
          terms: { company_ids: pnrCompanies }
        }
      ]
    };

    /* -------------
      Preferred Program Filter
    --------------- */
    const preferredProgramPublicVisibility = {
      match: {
        visibility: 'public'
      }
    };
    const preferredProgramsFilter = {
      should: [
        {
          bool: {
            must: [
              {
                match: {
                  source: 'sion'
                }
              },
              preferredProgramPublicVisibility
            ]
          }
        },
        {
          bool: {
            must: [
              {
                match: {
                  source: 'manual'
                }
              },
              {
                match: {
                  type: 'agency'
                }
              },
              {
                terms: { '_embedded.company.id': [companyId, ...hostAgencies] }
              },
              preferredProgramPublicVisibility
            ]
          }
        }
      ],
      minimum_should_match: 1
    };

    /* --------------
      Client Filter
    ---------------- */
    let clientCompanies = [];
    if (indexFilterConfig.clients?.company_ids?.length > 0) {
      clientCompanies = indexFilterConfig.clients.company_ids;
    } else {
      clientCompanies = [...companies];
      if (access.ic.clients.read) {
        clientCompanies = clientCompanies.concat(companyContractorIds);
      }
    }
    const clientFilter = {
      must: [getters.getCompaniesFilter(clientCompanies)]
    };
    if (indexFilterConfig.clients?.agent_ids?.length > 0) {
      // Filtering clients by agent_id was removed for the only existing use-case (booking modal)
      clientFilter.must.push({
        terms: { 'agent_id': indexFilterConfig.clients.agent_ids }
      })
    }
    if (!access.company.clients.read) {
      clientFilter.must.push({
        term: { 'agent_id': employeeId }
      })
    }

    /* ----------------
      Supplier Filter
    ------------------ */
    const supplierFilter = { must: [], must_not: [] };
    let supplierCompanies = [];

    if (indexFilterConfig.suppliers?.company_ids?.length > 0) {
      supplierCompanies = indexFilterConfig.suppliers.company_ids;
    } else {
      supplierCompanies = allCompanies;
    }

    const excludeSuppliers = indexFilterConfig?.suppliers?.exclude || [];
    if (excludeSuppliers.length > 0) {
      supplierFilter.must_not.push({ terms: { 'id': excludeSuppliers }})
    }

    switch(indexFilterConfig?.suppliers?.source) {
      default:
      case 'any':
        supplierFilter.must.push({
          bool: {
            should: [
              { term: { source: 'sion' } },
              {...getters.getCompaniesFilter(supplierCompanies)},
            ]
          }
        });
        break;
      case 'sion':
        supplierFilter.must.push({ term: { source: 'sion' } })
        break;
      case 'manual':
        supplierFilter.must = [getters.getCompaniesFilter(supplierCompanies)];
        supplierFilter.must.push({ term: { source: 'manual' } });
    }

    /* -----------------------
      Map filters to indexes
    ------------------------- */
    const indexesFilterMapping = {
      sion_batches: {
        must: [
          {
            'match': { '_embedded.company.id': companyId }
          }
        ]
      },
      sion_clients: clientFilter,
      sion_imports: {
        must: [getters.getCompaniesFilter(companies)]
      },
      sion_pnrs: pnrFilter,
      sion_preferred_programs: preferredProgramsFilter,
      sion_tags: tagsFilter,
      sion_suppliers: supplierFilter,
      sion_trips: tripsFilter,
    }

    /* ---------------------------
      Generate filters for query
    ----------------------------- */
    const indexesWithFilters = [];
    const filters = [];
    const generateFilters = () => {
      searchIndexes.forEach(searchIndex => {
        const filter = indexesFilterMapping[searchIndex];
        if (filter) {
          filters.push({
            bool: {
              filter: [{ term: { _index: searchIndex } }],
              ...filter
            }
          });
          indexesWithFilters.push(searchIndex);
        }
      });
    }
    generateFilters();
    const passThrough = { must_not: [{ terms: { _index: indexesWithFilters } }] };
    return [...filters, { bool: passThrough }];
  },
  getAutocompleteQueryByIndexes: (state, getters) => ({ val, searchIndexes, minScore, indexFilterConfig }) => {
    const indexQuery = {
      bool: {
        must: {
          bool: {
            should: [...getters.getIndexSpecificFilters(searchIndexes, indexFilterConfig)],
          }
        },
        should: [...getters.getAutocompleteFields(val, searchIndexes.map((i) => i.replace('sion_', '')))]
      }
    }

    const suppliersType = indexFilterConfig?.suppliers?.type || 'any';
    if (suppliersType !== 'any') {
      indexQuery.bool.should.push({
        term: {
          type: {
            value: suppliersType,
            boost: 100
          }
        }
      })
    }

    const baseIndexBoost = 4;
    return {
      'indices_boost': [
        { 'sion_supplier_groups': 5 },
        { 'sion_suppliers': 1.1 },
        { 'sion_tags': baseIndexBoost },
        { 'sion_trips': baseIndexBoost },
        { 'sion_clients': baseIndexBoost },
        { 'sion_pnrs': baseIndexBoost },
        { 'sion_imports': baseIndexBoost },
        { 'sion_batches': baseIndexBoost },
        { 'sion_airports': baseIndexBoost },
        { 'sion_cities': baseIndexBoost },
        { 'sion_countries': baseIndexBoost },
        { 'sion_geographic_regions': baseIndexBoost },
        { 'sion_neighborhoods': baseIndexBoost },
        { 'sion_preferred_programs': baseIndexBoost }
      ],
      'query': {
        'function_score': {
          'functions': [
            {
              'filter': { 'term': { 'source': 'sion' } },
              'weight': 1.2
            }
          ],
          'min_score': minScore,
          'query': indexQuery,
        }
      }
    };
  },
  getSuggestPayload: (state, getters, rootState, rootGetters) => (value, indexes, size = 36) => {
    const company = rootGetters['companies/company'];

    return {
      suggest: {
        suggestions: {
          prefix: value,
          completion: {
            field: 'suggest',
            size,
            contexts: {
              'company_id': company.id,
            },
          },
        },
      },
    };
  },
  filterSuggestions: (state, getters, rootState, rootGetters) => (defaultOptions) => {
    const {
      access,
      id: employeeId,
      company_id: companyId
    } = rootGetters['employees/currentEmployee'];

    let options = defaultOptions;
    if (!access.ic.bookings.read) {
      options = options.filter((opt) => {
        if (['sion_bookings', 'sion_payments'].includes(stripIndexVersioning(opt._index))) {
          return opt._source._embedded.company.id === companyId;
        } return true;
      });
    }
    if (!access.company.bookings.read) {
      options = options.filter((opt) => {
        if (['sion_bookings', 'sion_payments'].includes(stripIndexVersioning(opt._index))) {
          const isCurrentCompany = opt._source._embedded.company.id === companyId;
          const agentIsCurrentEmployee = opt._source._embedded.agent.id === employeeId;
          if (access.ic.bookings.read) {
            // Can see their own bookings/payments, and bookings/payments of contractors, but not of their team member's
            return agentIsCurrentEmployee || !isCurrentCompany;
          } else {
            return agentIsCurrentEmployee;
          }
        } return true;
      });
    }
    return options;
  },
};

const actions = {
  async onTypingSearch({ dispatch }, { typedValue, searchIndexes = [], size = 36, minScore = 6, indexFilterConfig }) {
    const autocompleteResults = await dispatch('onTypingHandlerDefault', { typedValue, searchIndexes, minScore, indexFilterConfig });
    const suggestResults = await dispatch('onTypingHandlerSuggest', { typedValue, searchIndexes, size });
    const results = autocompleteResults.concat(suggestResults);

    return results.map((result) => {
      result['_index'] = stripIndexVersioning(result._index);
      return result
    });
  },
  async onTypingHandlerDefault({ getters }, { typedValue, searchIndexes, minScore, indexFilterConfig }) {
    const nonSuggestIndexes = searchIndexes.filter(index => !suggestSupportedIndexes.includes(index));
    if (nonSuggestIndexes.length === 0) return [];

    const payload = getters.getAutocompleteQueryByIndexes({ val: typedValue, searchIndexes: nonSuggestIndexes, minScore, indexFilterConfig });
    const res = await SEARCH.post(`/${nonSuggestIndexes.join(',')}/_search`, payload);
    const { hits } = res.data;
    if (hits?.total.value > 0) {
      let results = [...hits.hits];
      if (searchIndexes.includes('sion_tags')) {
        results = dedupeResultsWithTags(results);
      }
      return results;
    } return [];
  },
  async onTypingHandlerSuggest({ getters }, { typedValue, searchIndexes, size }) {
    const suggestIndexes = searchIndexes.filter(index => suggestSupportedIndexes.includes(index));
    if (suggestIndexes.length === 0) return [];

    const payload = getters.getSuggestPayload(typedValue, suggestIndexes, size);
    const { data } = await SEARCH.post(`/${suggestIndexes.join(',')}/_search`, payload);
    if (data.suggest) {
      return getters.filterSuggestions(data.suggest.suggestions[0].options);
    } return [];
  },
}

export default {
  namespaced: true,
  actions,
  getters,
};


