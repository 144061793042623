import { apiClient } from '../apiClient';

// const DOMAIN = 'bulk_action';

export default {
  getBulkPresets: ({ type, ...params }) => apiClient.get(`/bulk_action_presets?type=${type}`, { params }),
  getWorkers: ({ id, ...params }) => apiClient.get(`/bulk_actions/${id}/workers`, { params }),
  read: ({ id, ...params }) => apiClient.get(`/bulk_actions/${id}`, { params }),
  create: (params) => apiClient.post('/bulk_actions', params),
};
