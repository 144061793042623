import Vue from 'vue';
import router from '@/router';
import * as Sentry from '@sentry/vue';
// Import specific modules needed
import { setUser, captureException, captureMessage, Replay } from '@sentry/browser';

export default () => {
  if (!Vue.prototype.$isProd && !Vue.prototype.$isStaging) return;
  Vue.prototype.$sentry = { setUser, captureException, captureMessage, Replay };
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /sioncentral.com.(?!.*socket\.io.*)/, /^\//],
      }),
      new Sentry.Replay({
        maskAllText: false,
        mask: ['[data-pii]']
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 100% for production, and 0% for other envs
    replaysSessionSampleRate: Vue.prototype.$isProd ? 1.0 : 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
};
